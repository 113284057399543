import Image1 from '../../assets/project1.jpg'
import Image2 from '../../assets/project2.jpg'
import Image3 from '../../assets/project3.jpg'
import Image4 from '../../assets/project4.jpg'
import Image5 from '../../assets/project5.jpg'
import Image6 from '../../assets/project6.jpg'
import Image7 from '../../assets/project7.jpg'
import Image8 from '../../assets/project8.jpg'
import Image9 from '../../assets/project9.jpg'
import Image10 from '../../assets/project10.jpg'
import Image11 from '../../assets/project11.jpg'
import Image12 from '../../assets/project12.jpg'
import Image13 from '../../assets/project13.jpg'
import Image14 from '../../assets/project14.jpg'

const data = [
    {
        id: 1,
        category: 'uiux',
        image: Image1,
        title: "Proyecto WowGames - Tienda Online (UIUX)",
        desc: "Ecommerce - Tienda online de Juegos, donde el usuario puede comprar y gestionar su pedido. Proyecto desarrollado en NextJS - Strapi.",
        demo: 'https://wowgames.netlify.app/',
        github: 'https://github.com/vhtoledo/ecommerce-next-wowgames'
    },
    {
        id: 2,
        category: 'frontend',
        image: Image2,
        title: "Proyecto Sitio Web de Dirección de Transportes (Frontend)",
        desc: "Sitio web diseñado con la finalidad de poder centralizar, todos los tramites relacionados con el transporte público de pasajeros.",
        demo: 'https://transportes.smt.gob.ar/',
        github: 'https://github.com/vhtoledo'
    },
    {
        id: 3,
        category: 'frontend',
        image: Image3,
        title: "Proyecto Sistema de Impresión Canon (Frontend)",
        desc: "Sistema de impresión de boleta del impuesto CANON, que habilita a los taxis a prestar servicios dentro de San Miguel de Tucumán.",
        demo: 'http://181.15.244.252/pago/',
        github: 'https://github.com/vhtoledo'
    },
    {
        id: 4,
        category: 'frontend',
        image: Image4,
        title: "Web Oficial de la Municipalidad de San Miguel de Tucumán (Frontend)",
        desc: "El sitio web tiene por finalidad informar, agrupar diferentes sistemas, centralizarlos, para que los usuarios tengan un acceso directo.",
        demo: 'https://smt.gob.ar/',
        github: 'https://github.com/vhtoledo'
    },
    {
        id: 5,
        category: 'frontend',
        image: Image5,
        title: "Proyecto Sitemas de Turnos Carnet de Manejo (frontend)",
        desc: "App Web donde los usuarios, pueden solicitar, registrarse como ciudadanos digitales y en este caso gestionar el turno para la licencia de conducir . ",
        demo: 'https://ciudaddigital.smt.gob.ar/?destino=turnero#/login',
        github: 'https://github.com/vhtoledo/TaakBord-App'
    },
    {
        id: 6,
        category: 'backend',
        image: Image6,
        title: "Proyectos Victor Hugo Toledo (Backend)",
        desc: "Repositorios de GITHUB públicos, de Victor Hugo Toledo, puedes encontrar diversidad de proyectos de código abierto.",
        demo: 'https://github.com/vhtoledo',
        github: 'https://github.com/vhtoledo'
    },
    {
        id: 7,
        category: 'frontend',
        image: Image7,
        title: "Proyecto Sitio Web oficial de Editorial (Frontend)",
        desc: "Sitio web oficial del ACV Folclore. Editorial musical del interior de Argentina",
        demo: 'https://www.acvfolclore.com.ar/',
        github: 'https://github.com/vhtoledo'
    },
    {
        id: 8,
        category: 'frontend',
        image: Image8,
        title: "Proyecto Sitio Web de Alma Music (Frontend)",
        desc: "Sitio web oficial de Alma Music. Sello discográfico, difusión y productora de artistas ",
        demo: 'https://www.almamusic.net.ar/',
        github: 'https://github.com/vhtoledo'
    },
    {
        id: 9,
        category: 'frontend',
        image: Image9,
        title: "Proyecto Sitio Web de Adoclic Advertising (Frontend)",
        desc: "Sitio web oficial de Adoclic Advertising. Especilistas en campañas publicitarias ",
        demo: 'https://adoclic.com/',
        github: 'https://github.com/vhtoledo'
    },
    {
        id: 10,
        category: 'frontend',
        image: Image10,
        title: "Proyecto Sitio Web Dirección General de Rentas DIM (Frontend)",
        desc: "App web oficial de Dirección de Ingresos Municipales, donde se pueden gestionar diversos tipos de trámites.",
        demo: 'https://www.dimsmt.gob.ar/',
        github: 'https://github.com/vhtoledo'
    },
    {
        id: 11,
        category: 'frontend',
        image: Image11,
        title: "Sitio Web Danne Sabores (Frontend)",
        desc: "Sitio web oficial, que tiene por finalidad brindar información de los productos de la empresa y como adquirirlos",
        demo: 'https://dannesabores.com/',
        github: 'https://github.com/vhtoledo'
    },
    {
        id: 12,
        category: 'frontend',
        image: Image12,
        title: "App Sistema de Gestión de Producción (Frontend)",
        desc: "Software que permite planificar, coordinar y controlar los procesos de producción en la industria.",
        demo: 'https://github.com/vhtoledo',
        github: 'https://github.com/vhtoledo'
    },
    {
        id: 13,
        category: 'frontend',
        image: Image13,
        title: "Ecommerce - Danne Sabores (Frontend)",
        desc: "Tienda Online donde se puede adquirar todos los productos de Danne Sabores.",
        demo: 'https://github.com/vhtoledo',
        github: 'https://github.com/vhtoledo'
    },
    {
        id: 14,
        category: 'uiux',
        image: Image14,
        title: "App Delivery Danne (UIUX)",
        desc: "App Delivery Propia Para Danne Sabores, donde pueden comprar y recibir su pedido en tiempo real",
        demo: 'https://github.com/vhtoledo',
        github: 'https://github.com/vhtoledo'
    }
]


export default data