const data = [
    {
      id: 1,
      question: "¿Qué necesita para empezar a trabajar en mi proyecto?",
      answer: "Depende principalmente del tipo de proyecto. Pero, en general, necesita una idea clara de lo que quiere, luego podemos tomarlo desde allí para ver lo que necesita."
    },
    {
      id: 2,
      question: "¿Cuánto tiempo tarda el proyecto en completarse?",
      answer: "Esto depende de la complejidad del proyecto, su disponibilidad y su pago. Una vez que haya resuelto esto, trabajamos en conjunto día a día,para estimar la entrega del producto."
    },
    {
      id: 5,
      question: "¿El alojamiento y el registro de dominios están incluidos en su precio?",
      answer: "¡No! El alojamiento y el dominio se gestionan por separado. Puede incluirse en el proyecto o puede usted mismo contratarlo, si sabe cómo hacerlo."
    },
    {
      id: 4,
      question: "¿Cuánto cuesta un sitio web o una aplicación promedio?",
      answer: "Una vez más, esto depende del proyecto. Los precios se pueden determinar cuando podamos charlar sobre lo que se necesita."
    },
    {
      id: 3,
      question: "¿Cuál es la forma de pago?",
      answer: "El pago se divide en 3 partes. Un pago inicial del 30 % por adelantado para comenzar una vez que se establecen todos los detalles del proyecto, el 40 % una vez que se completa el proyecto y el 30 % una vez que se entrega el proyecto."
    },
    {
      id: 6,
      question: "¿Qué sucede si el proyecto no sale bien?",
      answer: "Serás parte del proceso desde el principio. Sabrás cómo va todo el proyecto hasta el final. Me aseguro de brindarle lo mejor y todo lo que necesite."
    }
  ]

  export default data