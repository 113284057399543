import data from './data'
import Card from '../../components/Card'
import './about.css'

const About = () => {
  return (
    <section id="about" data-aos="fade-in">
        <div className="container about__container">
            <div className="about__left">
                <div className="about__portrait">
                    <img src="https://res.cloudinary.com/ddgxkqi07/image/upload/v1687322077/_MG_3624_wze5bz.jpg" alt="About Imange" />
                </div>
            </div>
            <div className="about__right">
                <h2>Software Engineer</h2>
                <p className='subtitulo'>Full Stack - DevOps</p>
                <div className="about__cards">
                    {
                        data.map(item => (
                            <Card key={item.id} className="about__card">
                                <span className='about__card-icon'>{item.icon}</span>
                                <h5>{item.title}</h5>
                                <small>{item.desc}</small>
                            </Card>
                        ))
                    }
                </div>
                <p>
                Construir proyectos que a mis clientes satisfaga siempre es mi objetivo. Estando en la industria del desarrollo web por más de 5 años, trabajando en el sector informático ya con más de 7 años de trayectoria en diversas áreas del mismo. ¡Siempre estoy motivado para nuevos desafíos!
                </p>
                <p>
                Hola, mi nombre es Victor Hugo Toledo. Me dedico a la Ingeniería de Software, con conocimientos en múltiples tecnologías y áreas del mundo informático. Mi principal prioridad es poner su producto en línea de la manera correcta, brindándole un diseño y toda la funcionalidad que necesita para operar sin problemas. Póngase en contacto hoy y empecemos con los detalles de su proyecto.
                </p>
                <p>
                    ¡Comencemos!...
                </p>
  
            </div>
        </div>
    </section>
  )
}

export default About