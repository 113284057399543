import Modal from "../components/Modal"
import './theme.css'
import {GrSystem} from 'react-icons/gr'
import {TbNetwork} from 'react-icons/tb'
import {GrDocumentConfig} from 'react-icons/gr'
import {MdDeveloperMode} from 'react-icons/md'
import {GrUserManager} from 'react-icons/gr'
import {SiGoogletagmanager} from 'react-icons/si'


const Theme = () => {
  return (
    <Modal className="theme__modal">
        <h3>MI FORMACIÓN PROFESIONAL</h3>
        <p className="subtitulo">Títulos obtenidos.</p>
        <img src="https://res.cloudinary.com/ddgxkqi07/image/upload/v1686790864/logo_pymodm.png" alt="Logo" className="logo"/>
        <div className="theme__primary-wrapper">
            <h4 className="items"><GrSystem/> Systems Analyst</h4>
            <h4 className="items"><TbNetwork/> Network Administrator IT</h4>
            <h4 className="items"><GrDocumentConfig/> Cybersecurity Analyst</h4>
            <h4 className="items"><MdDeveloperMode/> DevOps Aws Cloud</h4>
            <h4 className="items"><SiGoogletagmanager/> Product Owner / Scrum Master</h4>
            <h4 className="items"><GrUserManager/> Product Manager</h4>
        </div>
    </Modal>
  )
}

export default Theme