import { useEffect } from "react";
import data from "./data";
import AOS from "aos";
import "aos/dist/aos.css";
import "./header.css";

const Header = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <header id="header">
      <div className="container header__container">
        <div className="header__profile" data-aos="flip-up">
          <img src="https://res.cloudinary.com/ddgxkqi07/image/upload/v1687321722/_MG_3606-removebg-preview_dwdz1m.png" alt="Header Portait" />
        </div>
        <h2 data-aos="fade-up" className="titulo">
          Victor Toledo
        </h2>
        <p data-aos="fade-up">
          Creo soluciones de software, para que las empresas optimicen sus
          operaciones, a través de metodologías agiles. La escalabilidad 
          es una de las características clave del
          desarrollo, ya que las metodologías se centran en
          adaptarse a las nuevas oportunidades del mercado. Te brindo toda mi
          experiencia para que tu producto sea único.
        </p>
        <div className="header__cta" data-aos="fade-up">
          <a href="#contact" className="btn primary">
            Hablemos
          </a>
          <a href="#portfolio" className="btn light">
            Mis Trabajos
          </a>
        </div>
        <div className="header__socials">
          {data.map((item) => (
            <a
              key={item.id}
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.icon}
            </a>
          ))}
        </div>
      </div>
    </header>
  );
};

export default Header;
