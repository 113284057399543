import {FaAward} from 'react-icons/fa'
import {TbBooks} from 'react-icons/tb'


const data = [
    {id: 1, icon: <FaAward/>, title: 'Experiencia', desc: '5+ Años Diseñando y Desarrollando Sitios Web, Aplicaciones Web, Aplicaciones de Escritorio, Aplicaciones Móviles, etc...'},
    {id: 2, icon: <TbBooks/>, title: 'Proyectos', desc: 'He trabajado estando a cargo de diversos proyectos, en el ámbito gubernamental, sector privado y siendo dueño de productos en el mercado'},
    ]



    export default data;